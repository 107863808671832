import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { HomeStore } from 'store';

import { Col, Icon, Row } from 'antd';
import './style.scss';

function MobileMenu(props) {
  const { handleClose, isShown } = props;
  const { state } = useContext(HomeStore);

  return (
    <>
      {isShown && (
        <div className="pd-mobile-menu">
          <Row className="header">
            <Col xs={{ span: 22, offset: 1 }}>
              <Row>
                <Col xs={20} className="title">
                  <div className="img-container">
                    <img src={state.logo_image} alt="logo" />
                  </div>
                </Col>
                <Col
                  className="side-btn pd-align-right"
                  xs={4}
                  onClick={handleClose}>
                  <Icon type="close" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 22, offset: 1 }}>
              <div className="mobile-menu">
                <a href="#pd-home-banner" onClick={handleClose}>
                  <div className="menu">Home</div>
                </a>
                <a href="#pd-home-story" onClick={handleClose}>
                  <div className="menu">About Us</div>
                </a>
                <a href="#pd-home-gallery" onClick={handleClose}>
                  <div className="menu">Gallery</div>
                </a>
                <a href="#pd-home-location" onClick={handleClose}>
                  <div className="menu">Locations</div>
                </a>
                <a href="#pd-home-hiring" onClick={handleClose}>
                  <div className="menu">Career</div>
                </a>
                <a
                  href="https://www.instagram.com/banban.tea/"
                  onClick={handleClose}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="menu">
                    Visit Instagram
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

MobileMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default MobileMenu;
