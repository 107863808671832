import React, { useContext } from 'react';

import { Col, Row } from 'antd';

import Container from 'components/base/container';
import SectionTitle from 'components/base/section-title';
import HiringSquare from 'components/base/hiring-square';
import HtmlRender from 'components/base/html-reader';
import { HomeStore } from 'store';
import { get } from 'lodash';

import './style.scss';

export default function SectionOurStory() {
  const { state } = useContext(HomeStore);
  const hiring_message = get(state, 'hiring_message', 'no text');
  return (
    <section className="pd-home--hiring section">
      <Container>
        <Row>
          <Col xs={24}>
            <SectionTitle title="We're Hiring" />
          </Col>
          <Col xs={24}>
            <div className="hiring-container">
              <Row type="flex" align="middle">
                <Col xs={24} md={12}>
                  <HiringSquare />
                </Col>
                <Col xs={24} md={12}>
                  <div className="hiring-text description">
                    <HtmlRender content={hiring_message} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
