import React from 'react';
import { HomeStore, HomeProvider } from 'store/home-content';

/**
 * Wrapper function for all Provider
 * @param {*} props
 */
function StoreProvider({ children }) {
  return <HomeProvider>{children}</HomeProvider>;
}

export { StoreProvider, HomeStore, HomeProvider };
