import React, { useContext, useEffect } from 'react';

import SectionOurStory from 'components/section/sectionOurStory';
import SectionVideo from 'components/section/sectionVideo';
import SectionGallery from 'components/section/sectionGallery';
import SectionLocation from 'components/section/sectionLocation';
import SectionHiring from 'components/section/sectionHiring';
import SectionBanner from 'components/section/sectionBanner';
import SEO from 'components/seo';
import { PageSpinner } from 'components/page-spinner';
import { Header } from 'components/header';
import { Footer } from 'components/footer';

import { HomeStore } from 'store';

import './style.scss';

export function Home() {
  const { state, fetchHome } = useContext(HomeStore);

  useEffect(() => {
    fetchHome();
  // eslint-disable-next-line
  }, []);

  if (state.home_loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <SEO
        titlePage={state.meta_header}
        metaDescription={state.meta_description}
      />
      <Header />
      <div className="pd-home">
        <div id="pd-home-banner">
          <SectionBanner />
        </div>
        <div id="pd-home-story">
          <SectionOurStory />
        </div>
        <div id="pd-home-video">
          <SectionVideo />
        </div>
        <div id="pd-home-gallery">
          <SectionGallery />
        </div>
        <div id="pd-home-location">
          <SectionLocation />
        </div>
        <div id="pd-home-hiring">
          <SectionHiring />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default { Home };
