import React, { useState, useEffect } from 'react';

import { Icon } from 'antd';

import './style.scss';

export default function VideoPlayer({ videoUrl, isShown, handleIsShown }) {
  const [videoMedia, setVideoMedia] = useState('');

  useEffect(() => {
    setVideoMedia(videoUrl);
  }, [videoUrl]);

  return (
    <>
      {isShown && (
        <div className="pd-video-player">
          <div className="pd-video-player-close-btn">
            <Icon type="close" onClick={() => handleIsShown(false)} />
          </div>
          <div className="pd-video-player-overlay">
            <video controls autoPlay className="pd-video-player-media">
              <source src={videoMedia} type="video/mp4" />
              <source src={videoMedia} type="video/ogg" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
      )}
    </>
  );
}
