import React, { useContext } from 'react';

import { Col, Row, Tabs } from 'antd';

import Container from 'components/base/container';
import SmallSquareTile from 'components/base/small-square-tile';
import SectionTitle from 'components/base/section-title';

import { get } from 'lodash';
import { HomeStore } from 'store';

import './style.scss';

const { TabPane } = Tabs;

export default function SectionOurStory() {
  const { state } = useContext(HomeStore);

  const drink_galleries = get(state, 'drink_galleries', [
    'http://www.placehold.it/800x800',
  ]);
  const dessert_galleries = get(state, 'dessert_galleries', [
    'http://www.placehold.it/800x800',
  ]);

  return (
    <section className="pd-home--gallery section">
      <Container>
        <Row>
          <Col xs={24}>
            <SectionTitle title="Gallery" />
          </Col>
          <Col xs={24}>
            <div className="tabbed-tile description-medium">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Drinks" key="1">
                  <div className="tile-container">
                    <Row gutter={[15, 15]}>
                      {drink_galleries.map((x) => {
                        return (
                          <Col xs={12} md={8} key={x}>
                            <SmallSquareTile image={x} />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Desserts" key="2">
                  <div className="tile-container">
                    <Row gutter={[15, 15]}>
                      {dessert_galleries.map((x) => {
                        return (
                          <Col xs={12} md={8} key={x}>
                            <SmallSquareTile image={x} />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
