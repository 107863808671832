import React, { useState, useContext } from 'react';

import { Collapse } from 'react-collapse';
import { get } from 'lodash';

import { Col, Row, Card, Icon } from 'antd';

import Container from 'components/base/container';
import DesktopLocation from 'components/base/desktop-location';
import SectionTitle from 'components/base/section-title';

import { HomeStore } from 'store';

import './style.scss';

const { Meta } = Card;

export default function SectionOurStory() {
  const [showMore, setShowMore] = useState(false);
  const { state } = useContext(HomeStore);

  const locations = get(state, 'locations', [
    {
      title: 'No Location',
      address: '',
      image: 'http://www.placehold.it/800x800',
    },
  ]);
  const moreLocations = get(state, 'more_locations', [
    {
      title: 'No Location',
      address: '',
    },
  ]);

  return (
    <section className="pd-home--location section">
      <Container>
        <Row>
          <Col xs={24}>
            <SectionTitle title="Locations" />
          </Col>
          <Col xs={0} lg={24}>
            <Row gutter={[0, 10]}>
              {locations.map((x, i) => {
                return (
                  <Col lg={24} key={i}>
                    <div className="location-container">
                      <DesktopLocation
                        title={x.title}
                        description={x.address}
                        image={x.location_image}
                        gofoodLink={x.gofood_link}
                        grabfoodLink={x.grabfood_link}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col xs={24} lg={0}>
            <Row>
              {locations.map((x, i) => {
                return (
                  <Col xs={24} key={i}>
                    <div className="mobile-location">
                      <Card
                        hoverable
                        style={{ width: '100%' }}
                        cover={<img alt="example" src={x.location_image} />}
                      >
                        <Meta title={x.title} description={x.address} />
                        <Row className="additional-info">
                          <p className="margin-half">Order Now at</p>
                          <Col span={7}>
                            <a
                              href={x.gofood_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="courier-logo"
                                src={`${process.env.PUBLIC_URL}/img/gofood.png`}
                                alt="GoFood"
                              />
                            </a>
                          </Col>
                          <Col span={7} offset={1}>
                            <a
                              href={x.grabfood_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="courier-logo"
                                src={`${process.env.PUBLIC_URL}/img/grabfood.png`}
                                alt="GrabFood"
                              />
                            </a>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="more-locations">
          <Collapse isOpened={showMore}>
            <Row gutter={[{ lg: 180 }, { xs: 30, lg: 60 }]}>
              <Col xs={24}>
                <SectionTitle title="More Locations" />
              </Col>
              {moreLocations.map((x, i) => {
                return (
                  <Col xs={24} lg={8} key={i}>
                    <div className="address">
                      <h2 className="title title-3">{x.title}</h2>
                      <p className="description-italic">{x.address}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Collapse>
          {moreLocations.length >= 1 && (
            <Row>
              <Col xs={24}>
                <div className="see-more-container">
                  <span
                    className="see-more description"
                    onClick={() => setShowMore(!showMore)}>
                    <Icon type={showMore ? 'up' : 'down'} /> see
                    {showMore ? ' less' : ' more'}
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </section>
  );
}
