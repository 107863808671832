import React, { createContext, useReducer } from 'react';
import HomeApi from 'api/home-content';
import { notification } from 'antd';

export const HomeStore = createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_CONTENT':
      return {
        ...state,
        banner_image: action.banner_image,
        story_image: action.story_image,
        story_description: action.story_description,
        video_thumbnail: action.video_thumbnail,
        video_url: action.video_url,
        drink_galleries: action.drink_galleries,
        dessert_galleries: action.dessert_galleries,
        locations: action.locations,
        hiring_image: action.hiring_image,
        hiring_message: action.hiring_message,
        more_locations: action.more_locations,
        logo_image: action.logo_image,
        meta_header: action.meta_header,
        meta_description: action.meta_description,
      };
    case 'SET_LOADING':
      return {
        ...state,
        home_loading: action.home_loading,
      };
    default:
      return state;
  }
}

const initialState = {
  logo_image: '',
  banner_image: '',
  story_image: '',
  story_description: '',
  video_thumbnail: '',
  video_url: '',
  drink_galleries: [],
  dessert_galleries: [],
  locations: [],
  hiring_image: '',
  hiring_message: '',
  home_loading: false,
  more_locations: [],
  meta_header: '',
  meta_description: '',
};

export function HomeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchHome = () => {
    dispatch({
      type: 'SET_LOADING',
      home_loading: true,
    });
    HomeApi.getHomeContent()
      .then(({ data }) => {
        dispatch({
          type: 'FETCH_CONTENT',
          ...data,
        });
        dispatch({
          type: 'SET_LOADING',
          home_loading: false,
        });
      })
      .catch((e) => {
        dispatch({
          type: 'SET_LOADING',
          home_loading: false,
        });
        if (e.name === 'SyntaxError') {
          notification.open({
            message: 'Error Occured',
            description: 'Failed to fetch the language to the system',
          });
        } else {
          throw e;
        }
      });
  };

  const value = {
    state,
    dispatch,
    fetchHome,
  };

  return <HomeStore.Provider value={value}>{children}</HomeStore.Provider>;
}
