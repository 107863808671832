import React from 'react';
import { Col, Row } from 'antd';

import './style.scss';

export default function SquareTile({
  image, title, description, gofoodLink, grabfoodLink
}) {
  return (
    <div className="pd-home--desktop-location">
      <div className="overlay">
        <h1 className="title title-3">{title}</h1>
        <p className="description-italic">{description}</p>
        <p className="description margin-half">Order Now at</p>
        <Row>
          <Col span={10}>
            <a
              href={gofoodLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="courier-logo"
                src={`${process.env.PUBLIC_URL}/img/gofood.png`}
                alt="GoFood"
              />
            </a>
          </Col>
          <Col span={10} offset={1}>
            <a href={grabfoodLink}
              className="courier-logo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="courier-logo"
                src={`${process.env.PUBLIC_URL}/img/grabfood.png`}
                alt="GrabFood"
              />
            </a>
          </Col>
        </Row>
      </div>
      <div
        className="images"
        style={{
          backgroundImage: `url(${image})`,
        }}></div>
    </div>
  );
}
