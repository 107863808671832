import React, { useContext, useState } from 'react';

import { Col, Row, Icon } from 'antd';

import { HomeStore } from 'store';

import Container from 'components/base/container';
import VideoPlayer from 'components/base/video-player';

import './style.scss';

export default function SectionOurStory() {
  const { state } = useContext(HomeStore);
  const [videoVisibility, setVideoVisibility] = useState(false);
  return (
    <section className="pd-home--video section">
      <VideoPlayer
        isShown={videoVisibility}
        handleIsShown={setVideoVisibility}
        videoUrl={state.video_url}
        videoVisibility={videoVisibility}
      />
      <Container>
        <Row>
          <Col xs={24}>
            <div className="video-container">
              <div
                className="video"
                style={{
                  backgroundImage: `url(${state.video_thumbnail})`,
                }}>
                <div
                  className="play-icon"
                  onClick={() => setVideoVisibility(true)}>
                  <Icon type="play-circle" theme="filled" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
