import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Icon } from 'antd';
import { HomeStore } from 'store';
import './style.scss';
import MobileMenu from 'components/mobile-menu';
import Container from 'components/base/container';

export function Header() {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { state } = useContext(HomeStore);

  function handleMobileMenu(isOpen = false) {
    setMobileMenuVisible(isOpen);
  }
  return (
    <>
      <MobileMenu
        isShown={mobileMenuVisible}
        handleClose={() => handleMobileMenu(false)}
      />
      <header className="pd-header">
        <Container>
          <Row type="flex" align="bottom" gutter={16}>
            <Col xs={14} md={19} lg={5}>
              <Link to="/">
                <img
                  className="pd-header-logo"
                  src={state.logo_image}
                  alt="Company Logo"
                />
              </Link>
            </Col>
            <Col xs={0} md={0} lg={19}>
              <div className="pd-header-right">
                <a className="menu description-medium" href="#pd-home-banner">
                  Home
                </a>
                <a href="#pd-home-story" className="menu description-medium">
                  About Us
                </a>
                <a href="#pd-home-gallery" className="menu description-medium">
                  Gallery
                </a>
                <a href="#pd-home-location" className="menu description-medium">
                  Locations
                </a>
                <a href="#pd-home-hiring" className="menu description-medium">
                  Career
                </a>
                <a
                  href="https://linktr.ee/banbantea"
                  className="menu description-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Menu
                </a>
                <a
                  href="https://www.instagram.com/banban.tea/"
                  className="menu description-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="pd-header-btn">
                    Visit Instagram
                  </Button>
                </a>
              </div>
            </Col>
            <Col xs={6} md={3} lg={0}>
              <a
                href="https://linktr.ee/banbantea"
                className="menu description-medium"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="pd-header-btn">
                  Menu
                </Button>
              </a>
            </Col>
            <Col xs={4} md={2} lg={0}>
              <Icon
                className="menu-icon"
                onClick={() => setMobileMenuVisible(true)}
                type="menu"
              />
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
}

export default { Header };
