import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { StoreProvider } from 'store';

import { Home } from 'pages/home';

import 'antd/dist/antd.css';
import 'App.scss';

function App() {
  return (
    <Router>
      <StoreProvider>
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      </StoreProvider>
    </Router>
  );
}

export default App;
