import React from 'react';
import { Row, Col } from 'antd';

import './style.scss';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="pd-footer">
      <Row>
        <Col xs={24}>
          <div className="description copyright">
            &copy; {currentYear} Ban Ban. All rights reserved
          </div>
        </Col>
      </Row>
    </footer>
  );
}

export default { Footer };
