import React, { useContext } from 'react';

import { Col, Row } from 'antd';

import { get } from 'lodash';

import Container from 'components/base/container';
import BigSquareTile from 'components/base/big-square-tile';

import './style.scss';

import { HomeStore } from 'store';

export default function SectionOurStory() {
  const { state } = useContext(HomeStore);

  const story_image = get(
    state,
    'story_image',
    'http://www.placehold.it/800x800'
  );
  const story_description = get(
    state,
    'story_description',
    `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry standard dummy text
    ever since the 1500s, when an unknown printer took a galley of
    type and scrambled it to make a type specimen book. It has
    survived not only five centuries, when an unknown printer took a
    galley of type and scrambled it to make a type specimen book. It
    has survived not only five centuries,`
  );

  return (
    <section className="pd-home--our-story section">
      <Container>
        <Row type="flex" align="middle">
          <Col xs={24} md={10}>
            <BigSquareTile image={story_image} />
          </Col>
          <Col xs={24} md={14}>
            <div className="textContainer">
              <span className="title title-2">Our Story</span>
              <p className="description">{story_description}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
