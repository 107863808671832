import React, { useContext } from 'react';
import { HomeStore } from 'store';

import './style.scss';

export default function SectionOurStory() {
  const { state } = useContext(HomeStore);

  return (
    <section className="pd-home--banner">
      <div
        className="banner-container"
        style={{ backgroundImage: `url(${state.banner_image})` }}></div>
    </section>
  );
}
