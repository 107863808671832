import React from 'react';

import './style.scss';

export default function SquareTile({ image }) {
  return (
    <div
      className="pd-home--small-square-tile"
      style={{
        backgroundImage: `url(${image})`,
      }}></div>
  );
}
