import React, { useContext } from 'react';

import './style.scss';
import { HomeStore } from 'store';

export default function SquareTile() {
  const { state } = useContext(HomeStore);
  return (
    <div className="pd-home--hiring-square section">
      <div className="square back"></div>
      <div
        className="square front"
        style={{ backgroundImage: `url(${state.hiring_image})` }}></div>
    </div>
  );
}
