import React from 'react';

import './style.scss';

export default function SquareTile({ title }) {
  return (
    <div className="heading title title-2">
      <span>{title}</span>
      <hr className="line" />
    </div>
  );
}
